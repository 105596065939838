<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="6" lg="8" md="8" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent title="Application Consulter et modifier la Database Globale" />
          </div>

          <!-- la barre de progression -->
          <v-row justify="center" no-gutters>
            <v-col xl="6" lg="8" md="8" sm="10" xs="12">
              <!-- la progess bar à afficher lors du chargement des données -->
              <v-progress-linear
                indeterminate
                :active="loading"
              ></v-progress-linear>
            </v-col>
          </v-row>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Configuration</div>
              </v-row>
            </v-card-title>

            <v-card-text>
              <v-row no-gutters class="mb-5 mt-1" align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="text-uppercase font-weight-black mt-2">
                    LA FONCTION DOP
                  </div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-autocomplete
                    placeholder="Sélectionner la fonction"
                    :items="functions"
                    item-text="name"
                    item-value="id"
                    no-data-text="aucune fonction"
                    v-model="dopId"
                    readonly
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>            

            <v-divider></v-divider>

            <!-- <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="mx-2 mb-2"
                color="primary"
                
                @click="save"
                >ENREGISTRER</v-btn
              >
            </v-card-actions> -->
          </v-card>
        </v-col>
      </v-row>

    </div>
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";
import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";

import { AppConsulterModifierService } from "@/service/conf/app_consulter_modifier_service.js";
import { FonctionService } from "@/service/sfr/function_service.js";

import * as exceptions from "@/service/exception_to_message.js";


export default {
  name: "ConfViewEditDG",
  components: {
    Workflow,
    TitleAndReturnComponent,
  },

  mixins: [
    WorkflowMixin,
  ],

  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /** le service des accès aux apis */
      serviceConf : null,
      serviceFunction: null,

      /** la fonction dop originelle */
      source: null,
      
      /** la fonction sélectionnée */
      dopId: null,

      /** La liste des fonctions de la base */
      functions: [],
    };
  },

  methods: {
    /** Chargement des données */
    async load() {
      try {
        this.loading = true;

        // Récupération de la liste des fonctions
        this.functions = await this.serviceFunction.getFunctions();

        // Récupération de la fonction en base
        let dop = await this.serviceConf.getIdDop();
        if (dop) {
          this.dopId = dop.id;
          this.source = JSON.parse(JSON.stringify(dop));
        }

      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    /** sauvegarde des paramètres de conf de l'app */
    /** EN ATTENTE PAS DE ROUTE */
    async save() {

    },

    // Initialisation des étapes du workflow en fonction des droits de l'utilisateur
    initSteps() {
      this.addStepForWorkflow("Choisir une application");
      this.addStepForWorkflow("Modifier la configuration");
      this.nextStepForWorkflow();
    },

  }, // END methods

  mounted() {
    // Initialisation du worflow
    this.initSteps();

    // Instanciation des services
    this.serviceFunction = new FonctionService(this.$api.getFunctionApi());
    this.serviceConf = new AppConsulterModifierService(
      this.$api.getAppConsulterModifierApi()
    );

    // Chargement des datas
    this.load();
  },

};
</script>

<style></style>
