import * as statusMapper from "@/service/error_request_mapper.js";

export class AppConsulterModifierService {
  constructor(api) {
    this.api = api;
  }

  /** Service d'obtention de l'id de la fonction dop */
  async getIdDop() {
    return this.api.getIdDop().catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }
}
